<template>
    <div class="misc-wrapper">
        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center" v-if="!iosChromeControl">
                <div class="d-flex justify-content-center align-items-start mb-2">
                    <gameguru-logo style="width:45px;height: 45px;" class="mr-50" />
                    <h2 class="brand-text text-primary">
                        Gameguru
                        <small class="d-block text-center">Store</small>
                    </h2>
                </div>
                <p class="mb-25" v-if="device === 'ios' || device ==='android'">
                    To be able to use the application, please add it to your home screen.
                </p>
                <p class="mb-3" v-else style="font-size:17px">
                    Please login from your mobile device to use the Store app.
                </p>
                <hr v-if="device === 'ios' || device ==='android'">

                <p class="mb-3 font-weight-bold" v-if="device === 'ios'">
                    Just tap <feather-icon icon="ShareIcon" style="color:52a2fe" size="24"></feather-icon> then "Add
                    to
                    Home Screen"
                </p>
                <p class="mb-50 font-weight-bold" v-else-if="device === 'android'">
                    Just tap <feather-icon icon="MoreVerticalIcon" size="20"></feather-icon> then "<feather-icon
                        icon="SmartphoneIcon" size="20"></feather-icon> Add to Home Screen" <br />
                    <span v-if="!appInstalled">
                        <hr class="mt-50">
                        or
                        <b-button variant="outline-secondary" @click="installPwa()">Click to install</b-button>
                    </span>

                </p>
            </div>
            <div class="w-100 text-center" v-else>
                <b-img class="mb-2" fluid src="/app-store/safari-icon.svg" style="max-width:75px" alt="Safari icon" />
                <p class="mb-3 font-weight-bold" style="font-size:17px">
                    Please open the application via safari.
                </p>

            </div>

        </div>
    </div>
</template>
  
<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import GameguruLogo from '@/components/GameguruLogo.vue'
export default {
    components: {
        GameguruLogo,
        BImg,
        BButton
    },
    data() {
        return {
            device: 'desktop',
            iosChromeControl: false,
            deferredPrompt: null,
            appInstalled: false,
        }
    },
    mounted() {
        var isSafari = navigator.userAgent.indexOf('Safari') > -1 && (navigator.userAgent.indexOf('CriOS') <= -1 || !(/chrome|chromium|CriOS|crios/i.test(navigator.userAgent)))
        this.deviceRead()
        if (this.device === 'ios' && !isSafari) this.iosChromeControl = true
        if (this.device === 'android') {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.register("/app-store/service-worker.js").then(reg => {
                    console.log("Registration succesful, scope: " + reg.scope);
                })
                    .catch(err => {
                        console.log(err);
                    })
            }
            this.initializePwa()
        }
    },
    methods: {
        deviceRead() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera
            if (/android/i.test(userAgent)) this.device = 'android'
            if (/Macintosh|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) this.device = 'ios'
        },
        initializePwa() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                this.deferredPrompt = e
            })
            window.addEventListener('appinstalled', (e) => {
                e.preventDefault()
                this.appInstalled = true
            })
        },
        installPwa() {
            if (this.deferredPrompt !== null) {
                this.deferredPrompt.prompt()
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        this.appInstalled = true
                    }
                    this.deferredPrompt = null
                })
            }
        }
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
  